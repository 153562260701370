$(function () {
  $("#auditFromDate").datepicker({ 
        autoclose: true, 
       // todayHighlight: true
  });

  $("#generateLogReportDate").datepicker({
    autoclose: true,
    //todayHighlight: true
  });

  $("#effectiveDatepicker").datepicker({ 
    autoclose: true, 
   // todayHighlight: true
  });

  $("#WindowDatepicker").datepicker({ 
    autoclose: true, 
    // todayHighlight: true
  });
    $("#auditToDate").datepicker({ 
      autoclose: true, 
    // todayHighlight: true
  });
  $("#startDatepicker").datepicker({ 
    autoclose: true, 
    //todayHighlight: true
  });

  $("#packageWindowDatepicker").datepicker({ 
    autoclose: true, 
    //todayHighlight: true
    });

  $("#withImageDatepicker").datepicker({ 
    autoclose: true, 
    //todayHighlight: true
  });

  $("#restaurantDbDatePicker").datepicker({ 
    autoclose: true, 
    //todayHighlight: true
  });

  $("div[id^='promoStartDatePicker_']").datepicker({
    autoclose: true, 
    //todayHighlight: true
  });
  //CODE FOR NOT CLEAR THE INPUT FIELD WHEN SELECTING THE SAME DATE
  $("div[id^='promoStartDatePicker_']").on('show', function(e){
    if(e.date){
        $(this).data('stickyDate', e.date);
    } else {
        $(this).data('stickyDate', null);
    }
  });
  $("div[id^='promoStartDatePicker_']").on('hide', function(e){
      var stickyDate = $(this).data('stickyDate');
      if(!e.date && stickyDate){
          $(this).datepicker('setDate', stickyDate);
          $(this).data('stickyDate', null);
      }
  });

  $("div[id^='promoEndDatePicker_']").datepicker({
    autoclose: true, 
    //todayHighlight: true
  });
  //CODE FOR NOT CLEAR THE INPUT FIELD WHEN SELECTING THE SAME DATE
  $("div[id^='promoEndDatePicker_']").on('show', function(e){
    if(e.date){
        $(this).data('stickyDate', e.date);
    } else {
        $(this).data('stickyDate', null);
    }
  });
  $("div[id^='promoEndDatePicker_']").on('hide', function(e){
      var stickyDate = $(this).data('stickyDate');
      if(!e.date && stickyDate){
          $(this).datepicker('setDate', stickyDate);
          $(this).data('stickyDate', null);
      }
  });
});

$(function () {
  $("#switch")
  .button()
  .click(function(){
   
      $(".add-select-boxpanel").toggle("slide");
  });
});

 $(function () {
  $("#btnNext")
  .button()
  .click(function(){
   
      $(".add-select-boxpanel").toggle("slide");
  });
});

/* $(function () {
  $("#restBtnNext")
  .button()
  .click(function(){
    $("#collapseTwo").toggle("slide");
      $("#collapseThree").toggle("slide");
  });
}); */
