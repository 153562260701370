// JavaScript Document
jQuery(function () {
	jQuery(document).ready(function () {
		// if(jQuery(".card").hasClass("pkg-gen")){
		// 		jQuery("body").addClass("pkg-gen-wrp");
		// 	}
		// alert("hi");
		// event.stopImmediatePropagation();
	})
	//jQuery(".filter-btn").click(function(){
	jQuery(document).on('click', '.filter-btn i', function (event) {
		jQuery(".left-pane").toggleClass("closed").toggleClass("col-md-3");
		jQuery(".active-state-wrapper").toggle();
		jQuery(".right-pane").toggleClass("col-md-9").toggleClass("full-width");
		//jQuery(".left-pane .card").toggle();
		event.stopImmediatePropagation();
	});
	// jQuery(".menu-icon").click(function(){
	// 	jQuery(".overlay").toggle();
	// });
	jQuery(document).on('click', '.accordion_link', function (event) {
		if (!$(this).closest(".rfm-data-table").hasClass("interim_screen")) {
			var get_parent = jQuery(this).closest(".accord_parent");
			var get_sub_class = get_parent.attr("data-accord-ref");
			if (jQuery(this).hasClass("toggle")) {
				jQuery("." + get_sub_class).hide();
				jQuery(".pta_feature." + get_sub_class).each(function () {
					var get_sub_class_child = jQuery(this).attr("data-accord-ref");
					jQuery("." + get_sub_class_child).hide();
					jQuery(this).find(".accordion_link ").removeClass("toggle");
				})
				jQuery(this).removeClass("toggle");
			} else {
				jQuery("." + get_sub_class).show();
				jQuery(this).addClass("toggle");
			}
		}
		event.stopImmediatePropagation();
	})

	var expandCollapseFn = function () {
		jQuery(".accordion_link").each(function () {
			if (jQuery(this).hasClass("toggle")) {
			} else {
				if (!$(this).closest(".rfm-data-table").hasClass("interim_screen")) {
					var get_parent = jQuery(this).closest(".accord_parent");
					var get_sub_class = get_parent.attr("data-accord-ref");
					jQuery("." + get_sub_class).hide();
					jQuery(".pta_feature." + get_sub_class).each(function () {
						var get_sub_class_child = jQuery(this).attr("data-accord-ref");
						jQuery("." + get_sub_class_child).hide();
						jQuery(this).find(".accordion_link ").removeClass("toggle");
					})

				}
			}
		})
	}

	jQuery(document).on('click', ".multiselect-wrap-area li", function (event) {
		var attrValue = jQuery(this).attr('aria-label');
		if (attrValue == "Show Restaurants" || attrValue == "Afficher les restaurants") {
			expandCollapseFn();
		}
		event.stopImmediatePropagation();
	})

	jQuery(document).on('click', ".multiselect-wrap-area .ui-multiselect-header", function (event) {
		var textValue = jQuery(this).text();
		if (textValue == "Unselect All" || textValue == "Désélectionner tout") {
			expandCollapseFn();
		}
		event.stopImmediatePropagation();
	})

	jQuery(document).on('click', ".menu_item_anchor", function (event) {
		expandCollapseFn();
		event.stopImmediatePropagation();
	})

	jQuery(".caret").click(function (event) {
		jQuery(this).toggleClass("toggle");
		event.stopImmediatePropagation();
	})
});

window.MMIDAssociationDetailsReportFullExport = true;